<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-code': this.state == 'forgot-code',
        'login-forgot-on': this.state == 'forgot',
        'login-new-pass': this.state == 'new-pass',
        'login-change-pass': this.state == 'change-pass',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside flex-column flex-row-auto d-none d-lg-flex"
        style="background-color: #ffe4ba"
      >
        <div class="d-flex flex-column-auto flex-column mt-20">
          <div class="text-center mb-10">
            <img src="media/logos/robosay-logo.png" alt="Robosay Logo" />
          </div>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923"
          >
            Geleceğin apartman ve site yönetimini<br />Robosay'la keşfedin
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <div class="text-center mb-10 d-block d-lg-none">
                  <img src="media/logos/robosay-logo.png" alt="Robosay Logo" />
                </div>
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg text-center"
                >
                  Robosay'a Hoş Geldiniz
                </h3>
                <span class="text-muted font-weight-bold font-size-h4">
                  <!-- New Here? -->
                  <!-- <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Create an Account</a
                  > -->
                </span>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Telefon</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  class="position-relative"
                  label-for="example-input-1"
                >
                  <rs-tel-field
                    v-model="form.phone"
                    ref="phone"
                    required
                    name="phone"
                    label=""
                    autofocus
                    :rules="[
                      form.phone != '' ? rules.minLength(form.phone, 7) : null,
                      rules.numbersOnlyWithParam,
                    ]"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Parola</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 cursor-pointer"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Parolanızı mı unuttunuz?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  class="position-relative border-bottom border-dark"
                  label-for="example-input-2"
                >
                  <span
                    v-if="showpass"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-outline"
                    @click="passtoggle('hide')"
                  ></span>
                  <span
                    v-else
                    @click="passtoggle('show')"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-off-outline"
                  ></span>
                  <input
                    class="py-7 px-15 w-100"
                    :type="showpass ? 'text' : 'password'"
                    name="password"
                    ref="password"
                    placeholder="*******"
                    v-model="form.password"
                    autocomplete="off"
                    required
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  :disabled="isLoading"
                >
                  Giriş
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Parolamı Unuttum
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Parolanızı sıfırlamak için telefon numaranızı giriniz
                </p>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Telefon</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  class="position-relative"
                  label-for="example-input-1"
                >
                  <rs-tel-field
                    v-model="phone"
                    ref="phone"
                    required
                    name="phone"
                    label=""
                    :rules="[
                      phone != '' ? rules.minLength(phone, 7) : null,
                      rules.numbersOnlyWithParam,
                    ]"
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Gönder
                </button>
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Geri Dön
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
          <!--begin::Forgot-code-->
          <div class="login-form login-forgot-code">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_code_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Kod Doğrula
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Lütfen telefonunuza gelen sıfırlama kodunu giriniz
                </p>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Kod</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                  class="border-bottom border-dark"
                >
                  <input
                    class="py-7 w-100"
                    name="code"
                    placeholder="xxx"
                    ref="code"
                    v-model="code"
                    required
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_code_submit"
                  ref="kt_login_forgot_code_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Doğrula
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-code-->
          <!--begin::New-pass-->
          <div class="login-form login-new-pass">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_new_pass_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Yeni Parola
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Lütfen aşağıdaki alana yeni parolanızı giriniz
                </p>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Yeni Parola</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                  class="position-relative border-bottom border-dark"
                >
                  <span
                    v-if="showpass"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-outline"
                    @click="passtoggle('hide')"
                  ></span>
                  <span
                    v-else
                    @click="passtoggle('show')"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-off-outline"
                  ></span>
                  <input
                    class="py-7 px-15 w-100"
                    :type="showpass ? 'text' : 'password'"
                    name="newpassword"
                    ref="newpassword"
                    id="newpassword"
                    placeholder="*******"
                    v-model="newpassword"
                    autocomplete="off"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Yeni Parola Tekrar</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  class="position-relative border-bottom border-dark"
                  label-for="example-input-2"
                >
                  <span
                    v-if="showpass2"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-outline"
                    @click="passtoggle2('hide')"
                  ></span>
                  <span
                    v-else
                    @click="passtoggle2('show')"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-off-outline"
                  ></span>
                  <input
                    class="py-7 px-15 w-100"
                    :type="showpass2 ? 'text' : 'password'"
                    name="newpasswordre"
                    ref="newpasswordre"
                    placeholder="*******"
                    v-model="newpasswordre"
                    autocomplete="off"
                    required
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_new_pass_submit"
                  ref="kt_login_new_pass_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Değiştir
                </button>
              </div>
            </form>
          </div>
          <!--end::New-pass-->
          <!--begin::Change-pass-->
          <div class="login-form login-change-pass">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_change_pass_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Yeni Parola
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Lütfen aşağıdaki alana yeni parolanızı giriniz
                </p>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Yeni Parola</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  class="position-relative border-bottom border-dark"
                  label-for="example-input-2"
                >
                  <span
                    v-if="showpass"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-outline"
                    @click="passtoggle('hide')"
                  ></span>
                  <span
                    v-else
                    @click="passtoggle('show')"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-off-outline"
                  ></span>
                  <input
                    class="py-7 px-15 w-100"
                    :type="showpass ? 'text' : 'password'"
                    name="newpassword"
                    ref="newpassword"
                    id="newpassword"
                    placeholder="*******"
                    v-model="newpassword"
                    autocomplete="off"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Yeni Parola Tekrar</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  class="position-relative border-bottom border-dark"
                  label-for="example-input-2"
                >
                  <span
                    v-if="showpass2"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-outline"
                    @click="passtoggle2('hide')"
                  ></span>
                  <span
                    v-else
                    @click="passtoggle2('show')"
                    class="position-absolute h-100 d-flex align-items-center ml-5 mdi mdi-eye-off-outline"
                  ></span>
                  <input
                    class="py-7 px-15 w-100"
                    :type="showpass2 ? 'text' : 'password'"
                    name="newpasswordre"
                    ref="newpasswordre"
                    placeholder="*******"
                    v-model="newpasswordre"
                    autocomplete="off"
                    required
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_change_pass_submit"
                  ref="kt_login_change_pass_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Değiştir
                </button>
              </div>
            </form>
          </div>
          <!--end::New-pass-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a
            href="https://robosay.com.tr/robosay-nedir/"
            target="_blank"
            rel="noopener"
            class="text-success font-weight-bolder font-size-h5"
            >Nedir</a
          >
          <a
            href="https://robosay.com.tr/demo-talebi/"
            target="_blank"
            rel="noopener"
            class="text-success ml-10 font-weight-bolder font-size-h5"
            >Demo Talebi</a
          >
          <a
            href="https://robosay.com.tr/iletisim/"
            target="_blank"
            rel="noopener"
            class="text-success ml-10 font-weight-bolder font-size-h5"
            >İletişim</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import { hasForm } from "@/view/mixins";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

export default {
  mixins: [hasForm],
  name: "login-1",
  data() {
    return {
      state: "signin",
      showpass: false,
      showpass2: false,
      newpasswordre: null,
      newpassword: null,
      phone: null,
      code: null,
      user: null,
      form: {
        phone: null,
        password: null,
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    const forgot_code_form = KTUtil.getById("kt_login_forgot_code_form");
    const new_pass = KTUtil.getById("kt_login_new_pass_form");
    const change_pass = KTUtil.getById("kt_login_change_pass_form");

    this.fv = formValidation(signin_form, {
      fields: {
        phone: {
          validators: {
            notEmpty: {
              message: "Telefon alanı gereklidir",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Parola alanı gereklidir",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        phone: {
          validators: {
            notEmpty: {
              message: "Telefon alanı gereklidir",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv3 = formValidation(forgot_code_form, {
      fields: {
        code: {
          validators: {
            notEmpty: {
              message: "Kod alanı gereklidir",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv4 = formValidation(new_pass, {
      fields: {
        newpassword: {
          validators: {
            notEmpty: {
              message: "Yeni parola alanı gereklidir",
            },
          },
        },
        newpasswordre: {
          validators: {
            notEmpty: {
              message: "Yeni parola tekrar alanı gereklidir",
            },
            identical: {
              compare: function () {
                return KTUtil.getById("newpassword").value;
              },
              message: "Parolalar eşleşmiyor",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv5 = formValidation(change_pass, {
      fields: {
        newpassword: {
          validators: {
            notEmpty: {
              message: "Yeni parola alanı gereklidir",
            },
          },
        },
        newpasswordre: {
          validators: {
            notEmpty: {
              message: "Yeni parola tekrar alanı gereklidir",
            },
            identical: {
              compare: function () {
                return KTUtil.getById("newpassword").value;
              },
              message: "Parolalar eşleşmiyor",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      const phone = this.form.phone.replace(/[- )(]/g, "");
      const password = this.form.password;
      const device = navigator.userAgent;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const params = {
        phone,
        password,
        device_name: device,
        app: "web",
        version: "web",
      };

      this.isLoading = true;

      // send login request
      this.$store
        .dispatch(LOGIN, params)
        // go to which page after successfully login
        .then((response) => {
          this.user = response.data.user;
          if (this.user.has_temporary_password) {
            this.showForm("change-pass");
          } else if (this.user.consented_on === null) {
            this.$router.push({ name: "consent" });
          } else {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    this.fv2.on("core.form.valid", () => {
      const phone = this.phone.replace(/[- )(]/g, "");

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$api
        .post("forgot-password", { phone: phone })
        .then(() => {
          this.showForm("forgot-code");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv3.on("core.form.valid", () => {
      const phone = this.phone.replace(/[- )(]/g, "");
      const submitButton = this.$refs["kt_login_forgot_code_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const formData = {
        code: this.code,
        phone: phone,
      };

      this.$api
        .post("forgot-password-test", formData)
        .then(() => {
          this.showForm("new-pass");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv4.on("core.form.valid", () => {
      const phone = this.phone.replace(/[- )(]/g, "");
      const submitButton = this.$refs["kt_login_new_pass_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const formData = {
        code: this.code,
        phone: phone,
        password: this.newpassword,
      };

      this.$api
        .post("forgot-password-reset", formData)
        .then(() => {
          this.$toast.success(
            "Parolanız başarılı bir şekilde değiştirilmiştir"
          );
          this.showForm("signin");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    this.fv5.on("core.form.valid", () => {
      const submitButton = this.$refs["kt_login_change_pass_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const formData = {
        password: this.newpassword,
      };

      this.$api
        .post("password-change", formData)
        .then(() => {
          this.$toast.success("Parolanız başarılı bir şekilde güncellenmiştir");
          if (this.user.consented_on === null) {
            this.$router.push({ name: "consent" });
          } else {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  },
  methods: {
    passtoggle(type) {
      if (type == "show") {
        this.showpass = true;
      } else {
        this.showpass = false;
      }
    },
    passtoggle2(type) {
      if (type == "show") {
        this.showpass2 = true;
      } else {
        this.showpass2 = false;
      }
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
